import _cloneDeep from "lodash/cloneDeep";
import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { getBillCorrectionDetail, submitCorrection, getFeeTypes, getCheckoutDetail, getHouseList, getAccountType } from '@api/billCorrect';
import { getStorage } from '@utils/storage.js';
import { mapGetters } from 'vuex';
import { ImagePreview } from 'vant';
import store from '@store';
import dayjs from 'dayjs';
export default {
  name: 'BillDetail',
  mixins: [common],
  data() {
    return {
      detail: {},
      kemu1: '预收租金',
      kemu2: '押金',
      headerTitle: '详情',
      userInfo: JSON.parse(getStorage('user_info', 'localStorage')),
      showRoom: false,
      showDatePicker: false,
      showFeeType: false,
      feeTypes: [],
      showPaySource: false,
      paySources: ['租客', '业主'],
      costDate: '',
      // waterPaidAmount: 0, // 已收水费
      // waterTotalAmount: undefined, // 应收水费
      // powerPaidAmount: 0, // 已收电费
      // powerTotalAmount: undefined, // 应收电费
      roomNoSearch: '',
      rooms: [],
      correctRecordDetails: [],
      // 空间一级分类
      // 层级
      originCost: {},
      originDetail: {},
      originRent: {},
      form: {},
      cost: {},
      rent: {},
      showSettlement: false,
      settlements: [],
      showRentAmountDatePicker: false,
      factPayDate: '',
      payDate: ''
    };
  },
  watch: {
    'cost.type': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '费用科目', this.originCost, 'type');
    },
    'cost.description': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '费用描述', this.originCost, 'description');
    },
    'cost.houseId': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '关联房源', this.originCost, 'houseId');
    },
    'cost.amount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '金额', this.originCost, 'amount');
    },
    'cost.payerSource': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '费用承担者', this.originCost, 'payerSource');
    },
    'cost.costDate': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '费用发生时间', this.originCost, 'costDate');
    },
    'detail.waterPaidAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '已收水费', this.originDetail, 'waterPaidAmount');
    },
    'detail.waterTotalAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '应收水费', this.originDetail, 'waterTotalAmount');
    },
    'detail.powerPaidAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '已收电费', this.originDetail, 'powerPaidAmount');
    },
    'detail.powerTotalAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '应收电费', this.originDetail, 'powerTotalAmount');
    },
    'detail.damages': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '赔偿费', this.originDetail, 'damages');
    },
    'detail.cleanFee': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '打扫费', this.originDetail, 'cleanFee');
    },
    'detail.otherFee': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '额外增值费', this.originDetail, 'otherFee');
    },
    'rent.accountType': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '结算方式', this.originRent, 'accountType');
    },
    'rent.factRentAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '预收租金金额', this.originRent, 'factRentAmount');
    },
    'rent.factDepositAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '押金金额', this.originRent, 'factDepositAmount');
    },
    'rent.factPayDate': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '付款时间', this.originRent, 'factPayDate');
    }
  },
  computed: {
    ...mapGetters('user', ['projectInfo']),
    yingtuihuan() {
      if (this.detail.customerType == 1) {
        return (this.detail.inDeposit * 1 + this.waterElectricity * 1).toFixed(2);
      } else if (this.detail.customerType == 2 || this.detail.customerType == 3) {
        return (this.detail.afterRentBreakAmount * 1 + this.detail.inDeposit * 1 + this.waterElectricity * 1).toFixed(2);
      } else {
        return 0;
      }
    },
    yingjiaona() {
      console.log(this.detail, 'detail');
      if (this.detail.customerType == 1 && this.detail.chargeAmount !== 0) {
        return (this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2);
      } else if (this.detail.customerType == 2 && this.detail.chargeAmount !== 0) {
        return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2);
      } else if (this.detail.customerType == 3 && this.detail.chargeAmount !== 0) {
        return (this.detail.breakAmount * 2 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2);
      } else if (this.detail.customerType == 1) {
        return (this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1).toFixed(2);
      } else if (this.detail.customerType == 2) {
        return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1).toFixed(2);
      } else if (this.detail.customerType == 3) {
        return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.rentBreakAmount * 1).toFixed(2);
      } else {
        return 0;
      }
    },
    shiji() {
      return (this.yingtuihuan * 1 - this.yingjiaona * 1).toFixed(2);
    },
    zhinaday() {
      return this.detail.overdueDays * 1;
    },
    zhinajin() {
      // return (this.detail.monthRentAmount / 30 * this.zhinaday).toFixed(2)
      return this.detail.rentBreakAmount * 1;
    },
    // 水费结余
    waterReturnAmount() {
      return this.detail.waterPaidAmount - this.detail.waterTotalAmount || 0;
    },
    // 电费结余
    powerReturnAmount() {
      return this.detail.powerPaidAmount - this.detail.powerTotalAmount || 0;
    },
    // 水电结余费
    waterElectricity: {
      get() {
        return this.waterReturnAmount + this.powerReturnAmount || 0;
      },
      set(val) {
        return val;
      }
    }
  },
  created() {
    this.listFeeTypes();
    this.listAccountTypes();
    this.getDetail();
  },
  mounted() {},
  methods: {
    handleUpdateValue(newVal, oldVal, fieldName, source, originField) {
      if (this.form.correctType === 1 || this.form.correctType === 3 || this.form.correctType === 5 && oldVal || this.form.correctType === 2 && oldVal) {
        if (newVal != source[originField]) {
          let _obj_ = {
            projectId: this.projectInfo.dcProjectId,
            fieldName: fieldName,
            originalValue: source[originField],
            updateValue: newVal,
            recordId: this.form.id,
            createBy: store.getters['user/userInfo'].nickName
          };
          let findIdx = this.correctRecordDetails.findIndex(item => item.fieldName === fieldName);
          if (findIdx > -1) {
            this.correctRecordDetails.splice(findIdx, 1, _obj_);
          } else {
            this.correctRecordDetails.push(_obj_);
          }
        }
      }
    },
    listAccountTypes() {
      getAccountType().then(res => {
        // console.log(res.data.data)
        this.settlements = res.data.data;
      });
    },
    listFeeTypes() {
      getFeeTypes({
        dcProjectId: this.projectInfo.dcProjectId
      }).then(res => {
        // console.log(res)
        this.feeTypes = res.data.data;
      });
    },
    listRooms() {
      getHouseList({
        dcProjectId: this.projectInfo.dcProjectId,
        roomNo: this.roomNoSearch
      }).then(res => {
        const callback = res.data;
        console.log(callback);
        this.rooms = callback.result;
        // console.log(res)
      });
    },

    getDetail() {
      this.$store.dispatch('base/SetLoading', true);
      getBillCorrectionDetail({
        correctRecordId: this.$route.query.id
      }).then(res => {
        const cb = res.data;
        if (cb.code === 200) {
          this.form = cb.data.correctRecord;
          if (this.form.correctType === 3) {
            this.listRooms();
          }
          if (this.form.correctType) {
            this.form.correctTypeName = this.typeFilter(this.form.correctType);
          }
          if (this.form.fileList) {
            this.form.fileList = this.form.fileList.split(',');
          }
          if (cb.data.bill && typeof cb.data.bill === 'string') {
            getCheckoutDetail(cb.data.bill).then(res => {
              if (res.data.data) {
                this.detail = res.data.data;
                this.originDetail = _cloneDeep(res.data.data);
              }
            });
            // this.detail = cb.data.bill
          } else if (cb.data.bill && typeof cb.data.bill === 'object') {
            this.cost = cb.data.bill;
            if (this.cost.costDate) {
              this.cost.costDate = dayjs(this.cost.costDate).format('YYYY/MM/DD');
              this.costDate = new Date(this.cost.costDate);
            }
            this.originCost = _cloneDeep(cb.data.bill);
          } else if (cb.data.rentCorrectDTO) {
            this.rent = cb.data.rentCorrectDTO;
            this.originRent = _cloneDeep(cb.data.rentCorrectDTO);
            if (this.rent.startDate) {
              this.payDate = `${this.rent.startDate} ~ ${this.rent.endDate}`;
            }
            this.factPayDate = new Date(this.rent.factPayDate);
          }
        }
      }).finally(() => {
        this.$store.dispatch('base/SetLoading', false);
      });
    },
    onSubmit() {
      // console.log(this.form, this.cost, this.correctRecordDetails)
      const params = {
        correctRecord: this.form,
        correctRecordDetails: this.correctRecordDetails
      };
      if (params.correctRecord.fileList && params.correctRecord.fileList.length) {
        params.correctRecord.fileList = params.correctRecord.fileList.join(',');
      }
      // 非退房纠正
      if ([2, 3].includes(this.form.correctType)) {
        params.cost = this.cost;
        if (!params.cost.projectId) {
          params.cost.projectId = this.projectInfo.dcProjectId;
        }
      } else if (this.form.correctType === 1) {
        // 退房纠正
        params.powerPaidAmount = this.detail.powerPaidAmount;
        params.powerReturnAmount = this.powerReturnAmount;
        params.powerTotalAmount = this.detail.powerTotalAmount;
        params.waterElectricity = this.waterElectricity;
        params.waterPaidAmount = this.detail.waterPaidAmount;
        params.waterReturnAmount = this.waterReturnAmount;
        params.waterTotalAmount = this.detail.waterTotalAmount;
        params.additionalFee = this.detail.otherFee;
        params.damagesAmount = this.detail.damages;
        params.cleanAmount = this.detail.cleanFee;
      } else if (this.form.correctType === 5) {
        // 账单纠正
        params.rentCorrectDTO = this.rent;
      }
      console.log(params);
      submitCorrection(params).then(res => {
        const cb = res.data;
        if (cb.code === 200) {
          this.$toast('提交成功');
          this.goBack();
        }
      });
    },
    // 图片预览
    reviewImg(imgList) {
      const result = imgList.map(item => {
        return item;
      });
      ImagePreview(result);
    },
    changeDate(e) {
      this.cost.costDate = dayjs(this.costDate).format('YYYY-MM-DD');
      this.showDatePicker = false;
    },
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let date = time.getDate();
      let hour = time.getHours();
      if (month < 10) month = '0' + month;
      if (date < 10) date = '0' + date;
      if (hour < 10) hour = '0' + hour;
      return year + '-' + month + '-' + date + ' ' + hour + ':00:00';
    },
    handleShowTypeName() {
      if (this.form.correctType !== 4) {
        this.showFeeType = true;
      }
    },
    handleShowPayerSource() {
      if (this.form.correctType !== 4) {
        this.showPaySource = true;
      }
    },
    handleShowDate() {
      if (this.form.correctType !== 4) {
        this.showDatePicker = true;
      }
    },
    handleShowRoom() {
      if (this.form.correctType === 3) {
        this.showRoom = true;
      }
    },
    roomChange(room) {
      console.log(room);
      this.cost.houseId = room.id;
      this.cost.roomNo = room.roomNo;
      this.roomNoSearch = '';
      this.showRoom = false;
    },
    feeTypeChange(item) {
      console.log(item);
      this.cost.typeName = item.text;
      this.cost.type = item.value;
      this.showFeeType = false;
    },
    feeChargerChange(item) {
      this.cost.payerSource = item;
      this.showPaySource = false;
    },
    typeFilter(val) {
      switch (parseInt(val)) {
        case 1:
          return '退房纠正';
        case 2:
          return '其他费用纠正';
        case 3:
          return '补录纠正';
        case 4:
          return '退回纠正';
        case 5:
          return '账单纠正';
        default:
          return '--';
      }
    },
    // 账单纠正
    handleShowSettlement() {
      if (this.form.correctStatus != 3) {
        this.showSettlement = true;
      }
    },
    settlementConfirm(item) {
      console.log(item);
      this.rent.accountType = item.text;
      // this.rent.settlement = item.value
      this.showSettlement = false;
    },
    handleShowRentAmountDate() {
      if (this.form.correctStatus != 3) {
        this.showRentAmountDatePicker = true;
      }
    },
    changeRentAmountDate(e) {
      this.rent.factPayDate = dayjs(this.factPayDate).format('YYYY-MM-DD HH:mm:ss');
      this.showRentAmountDatePicker = false;
    }
  },
  filters: {}
  //
};