var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.headerTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-driections-navbar"
        }, [_c('van-form', {
          on: {
            "submit": _vm.onSubmit
          }
        }, [_c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "发起人",
            "input-align": "right"
          },
          model: {
            value: _vm.form.createBy,
            callback: function ($$v) {
              _vm.$set(_vm.form, "createBy", $$v);
            },
            expression: "form.createBy"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "发起时间",
            "input-align": "right"
          },
          model: {
            value: _vm.form.createTime,
            callback: function ($$v) {
              _vm.$set(_vm.form, "createTime", $$v);
            },
            expression: "form.createTime"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "纠正类型",
            "input-align": "right"
          },
          model: {
            value: _vm.form.correctTypeName,
            callback: function ($$v) {
              _vm.$set(_vm.form, "correctTypeName", $$v);
            },
            expression: "form.correctTypeName"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "rows": "3",
            "autosize": "",
            "maxlength": "100",
            "type": "textarea",
            "label": "问题描述",
            "input-align": "right"
          },
          model: {
            value: _vm.form.correctDesc,
            callback: function ($$v) {
              _vm.$set(_vm.form, "correctDesc", $$v);
            },
            expression: "form.correctDesc"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "label": "图片",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function () {
              return _vm._l(_vm.form.fileList, function (item, index) {
                return _c('img', {
                  key: index,
                  staticClass: "image",
                  attrs: {
                    "src": item,
                    "alt": ""
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.reviewImg(_vm.form.fileList);
                    }
                  }
                });
              });
            },
            proxy: true
          }])
        }), _c('van-divider'), [2, 3, 4].includes(_vm.form.correctType) ? _c('div', [_vm.form.correctType == 4 ? _c('van-notice-bar', {
          attrs: {
            "scrollable": false,
            "text": "该费用录入已退回，请到智慧平台系统核查"
          }
        }) : _vm._e(), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "name": "picker",
            "label": "费用科目",
            "placeholder": "请选择",
            "input-align": "right",
            "right-icon": "arrow",
            "required": "",
            "rules": [{
              required: true
            }]
          },
          on: {
            "click": _vm.handleShowTypeName
          },
          model: {
            value: _vm.cost.typeName,
            callback: function ($$v) {
              _vm.$set(_vm.cost, "typeName", $$v);
            },
            expression: "cost.typeName"
          }
        }), _c('van-popup', {
          attrs: {
            "position": "bottom"
          },
          model: {
            value: _vm.showFeeType,
            callback: function ($$v) {
              _vm.showFeeType = $$v;
            },
            expression: "showFeeType"
          }
        }, [_c('van-picker', {
          attrs: {
            "title": "费用科目",
            "show-toolbar": "",
            "columns": _vm.feeTypes
          },
          on: {
            "confirm": _vm.feeTypeChange,
            "cancel": function ($event) {
              _vm.showFeeType = false;
            }
          }
        })], 1), _c('van-field', {
          attrs: {
            "clickable": "",
            "placeholder": "请输入",
            "label": "费用描述",
            "rows": "3",
            "autosize": "",
            "maxlength": "100",
            "type": "textarea",
            "input-align": "right",
            "rules": [{
              required: true
            }],
            "disabled": _vm.form.correctType == 4,
            "required": ""
          },
          model: {
            value: _vm.cost.description,
            callback: function ($$v) {
              _vm.$set(_vm.cost, "description", $$v);
            },
            expression: "cost.description"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "name": "picker",
            "label": "关联房源",
            "placeholder": "请选择",
            "input-align": "right",
            "right-icon": "arrow",
            "required": "",
            "disabled": _vm.form.correctType == 4,
            "rules": [{
              required: true
            }]
          },
          on: {
            "click": _vm.handleShowRoom
          },
          model: {
            value: _vm.cost.roomNo,
            callback: function ($$v) {
              _vm.$set(_vm.cost, "roomNo", $$v);
            },
            expression: "cost.roomNo"
          }
        }), _c('van-popup', {
          attrs: {
            "position": "bottom"
          },
          model: {
            value: _vm.showRoom,
            callback: function ($$v) {
              _vm.showRoom = $$v;
            },
            expression: "showRoom"
          }
        }, [_c('van-search', {
          attrs: {
            "placeholder": "请输入房号"
          },
          on: {
            "search": _vm.listRooms
          },
          model: {
            value: _vm.roomNoSearch,
            callback: function ($$v) {
              _vm.roomNoSearch = $$v;
            },
            expression: "roomNoSearch"
          }
        }), _c('van-picker', {
          attrs: {
            "title": "关联房源",
            "value-key": "roomNo",
            "show-toolbar": "",
            "columns": _vm.rooms
          },
          on: {
            "confirm": _vm.roomChange,
            "cancel": function ($event) {
              _vm.showRoom = false;
            }
          }
        })], 1), _c('van-field', {
          attrs: {
            "clickable": "",
            "label": "金额",
            "placeholder": "请输入金额",
            "input-align": "right",
            "disabled": _vm.form.correctType == 4,
            "rules": [{
              required: true
            }],
            "required": ""
          },
          model: {
            value: _vm.cost.amount,
            callback: function ($$v) {
              _vm.$set(_vm.cost, "amount", $$v);
            },
            expression: "cost.amount"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "name": "picker",
            "label": "费用承担者",
            "placeholder": "请选择",
            "input-align": "right",
            "right-icon": "arrow",
            "required": "",
            "rules": [{
              required: true
            }]
          },
          on: {
            "click": _vm.handleShowPayerSource
          },
          model: {
            value: _vm.cost.payerSource,
            callback: function ($$v) {
              _vm.$set(_vm.cost, "payerSource", $$v);
            },
            expression: "cost.payerSource"
          }
        }), _c('van-popup', {
          attrs: {
            "position": "bottom"
          },
          model: {
            value: _vm.showPaySource,
            callback: function ($$v) {
              _vm.showPaySource = $$v;
            },
            expression: "showPaySource"
          }
        }, [_c('van-picker', {
          attrs: {
            "title": "费用承担者",
            "show-toolbar": "",
            "columns": _vm.paySources
          },
          on: {
            "confirm": _vm.feeChargerChange,
            "cancel": function ($event) {
              _vm.showPaySource = false;
            }
          }
        })], 1), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "name": "picker",
            "label": "费用发生时间",
            "placeholder": "请选择",
            "input-align": "right",
            "right-icon": "arrow",
            "required": "",
            "disabled": _vm.form.correctType == 4,
            "rules": [{
              required: true
            }]
          },
          on: {
            "click": _vm.handleShowDate
          },
          model: {
            value: _vm.cost.costDate,
            callback: function ($$v) {
              _vm.$set(_vm.cost, "costDate", $$v);
            },
            expression: "cost.costDate"
          }
        }), _c('van-popup', {
          attrs: {
            "round": "",
            "position": "bottom"
          },
          model: {
            value: _vm.showDatePicker,
            callback: function ($$v) {
              _vm.showDatePicker = $$v;
            },
            expression: "showDatePicker"
          }
        }, [_c('van-datetime-picker', {
          attrs: {
            "type": "date",
            "title": "选择年月日"
          },
          on: {
            "confirm": _vm.changeDate,
            "cancel": function ($event) {
              _vm.showDatePicker = false;
            }
          },
          model: {
            value: _vm.costDate,
            callback: function ($$v) {
              _vm.costDate = $$v;
            },
            expression: "costDate"
          }
        })], 1), _c('van-field', {
          attrs: {
            "label": "备注",
            "rows": "3",
            "autosize": "",
            "maxlength": "100",
            "type": "textarea",
            "placeholder": "请输入",
            "input-align": "right",
            "error-message-align": "left",
            "disabled": _vm.form.correctType == 4,
            "show-word-limit": ""
          },
          model: {
            value: _vm.cost.remark,
            callback: function ($$v) {
              _vm.$set(_vm.cost, "remark", $$v);
            },
            expression: "cost.remark"
          }
        })], 1) : _vm._e(), _vm.form.correctType == 1 ? _c('div', [_c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "title": "租客姓名",
            "value": _vm.detail.customerName
          }
        }), _c('van-cell', {
          attrs: {
            "title": "房号",
            "value": _vm.detail.roomNo
          }
        }), _c('van-cell', {
          attrs: {
            "title": "开始时间",
            "value": _vm.detail.contractStartTime
          }
        }), _c('van-cell', {
          attrs: {
            "title": "结束时间",
            "value": _vm.detail.contractEndTime
          }
        }), _c('van-cell', {
          attrs: {
            "title": "退房类型"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_vm.detail.customerType == 1 ? _c('span', {
                staticStyle: {
                  "color": "#21D788"
                }
              }, [_vm._v("正常退房")]) : _vm._e(), _vm.detail.customerType == 2 ? _c('span', {
                staticStyle: {
                  "color": "#FDA93E"
                }
              }, [_vm._v("提前退房")]) : _vm._e(), _vm.detail.customerType == 3 ? _c('span', {
                staticStyle: {
                  "color": "#FDA93E"
                }
              }, [_vm._v("提前退房（逾期）")]) : _vm._e()];
            },
            proxy: true
          }], null, false, 762878952)
        }), _c('van-cell', {
          attrs: {
            "title": "离店日期",
            "value": _vm.detail.leaveTime
          }
        }), _c('van-cell', {
          attrs: {
            "title": "退房原因",
            "title-style": "flex:0.5;",
            "value": _vm.detail.personalReason
          }
        }), _c('van-cell', {
          attrs: {
            "title": "退款时间",
            "title-style": "flex:0.5;",
            "value": "7个工作日内"
          }
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#999999"
                }
              }, [_vm._v("水费标准：" + _vm._s(_vm.detail.waterPrice) + "元/吨")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#999999"
                }
              }, [_vm._v("电费标准：" + _vm._s(_vm.detail.wattPrice) + "元/度")])];
            },
            proxy: true
          }], null, false, 2963222286)
        }), _c('van-cell', {
          attrs: {
            "title": "签约时水表度数",
            "value": (_vm.detail.checkInWaterDegree || '') + '吨'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "签约时电表度数",
            "value": (_vm.detail.checkInElectricityDegree || '') + '度'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "当前水表抄表度数",
            "value": (_vm.detail.waterMaterDegree || '') + '吨'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "当前电表抄表度数",
            "value": (_vm.detail.wattMaterDegree || '') + '度'
          }
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "14px"
                }
              }, [_vm._v("水表图片")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "14px",
                  "display": "block",
                  "width": "100%",
                  "text-align": "left",
                  "color": "#323233"
                }
              }, [_vm._v("电表图片")])];
            },
            proxy: true
          }], null, false, 3460980890)
        }), _c('van-cell', {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "14px",
                  "display": "block"
                }
              }, [_c('van-image', {
                attrs: {
                  "width": "140",
                  "height": "77",
                  "lazy-load": "",
                  "src": _vm.detail.wattMaterDegreeImg
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "14px",
                  "display": "block",
                  "width": "100%",
                  "text-align": "left",
                  "color": "#323233"
                }
              }, [_c('van-image', {
                attrs: {
                  "width": "140",
                  "height": "77",
                  "lazy-load": "",
                  "src": _vm.detail.waterMaterDegreeImg
                }
              })], 1)];
            },
            proxy: true
          }], null, false, 3036155197)
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "15px",
                  "font-weight": "400"
                }
              }, [_vm._v("退房费明细")])];
            },
            proxy: true
          }], null, false, 126704736)
        }), _vm.detail.customerType == 2 || _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm.detail.customerType == 2 ? _c('span', {
                staticStyle: {
                  "font-size": "15px",
                  "font-weight": "400"
                }
              }, [_vm._v("剩余月份租金明细")]) : _vm._e(), _vm.detail.customerType == 3 ? _c('span', {
                staticStyle: {
                  "font-size": "15px",
                  "font-weight": "400"
                }
              }, [_vm._v("退房逾期租金明细")]) : _vm._e()];
            },
            proxy: true
          }, {
            key: "label",
            fn: function () {
              return [_vm.detail.customerType == 2 ? _c('span', [_vm._v(_vm._s((_vm.detail.afterCheckOutRentStartDate ? _vm.detail.afterCheckOutRentStartDate : '') + (_vm.detail.afterCheckOutRentStartDate || _vm.detail.afterCheckOutRentEndDate ? '~' : '') + (_vm.detail.afterCheckOutRentEndDate ? _vm.detail.afterCheckOutRentEndDate : '')))]) : _vm._e(), _vm.detail.customerType == 3 ? _c('span', [_vm._v(_vm._s((_vm.detail.overdueStartDate ? _vm.detail.overdueStartDate : '') + (_vm.detail.overdueStartDate || _vm.detail.leaveTime ? '~' : '') + (_vm.detail.leaveTime ? _vm.detail.leaveTime : '')))]) : _vm._e()];
            },
            proxy: true
          }], null, false, 4095853851)
        }) : _vm._e(), _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v("月总租金")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v(_vm._s(_vm.detail.monthRentAmount) + "元")])];
            },
            proxy: true
          }], null, false, 3128501556)
        }) : _vm._e(), _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "border": false
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v("日均租金")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v(_vm._s(_vm.detail.dailyRentAmount) + "元")])];
            },
            proxy: true
          }], null, false, 2542039852)
        }) : _vm._e(), _vm.detail.customerType == 3 ? _c('van-cell', {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v("滞纳金")])];
            },
            proxy: true
          }, {
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v(_vm._s(_vm.zhinajin) + "元")])];
            },
            proxy: true
          }, {
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#666666"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.monthRentAmount || '') + "/" + _vm._s(_vm.detail.monthMaxDay || '') + "天*" + _vm._s(_vm.zhinaday || '') + "=" + _vm._s(_vm.zhinajin || '') + "元)")])];
            },
            proxy: true
          }], null, false, 3961683613)
        }) : _vm._e(), _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "title": "逾期租金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.rentBreakAmount) + "元")])];
            },
            proxy: true
          }], null, false, 1227362484)
        }) : _vm._e(), _vm.detail.customerType == 2 || _vm.detail.customerType == 3 ? _c('van-cell', {
          attrs: {
            "title": "违约金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.breakAmount) + "元")])];
            },
            proxy: true
          }], null, false, 1648129881)
        }) : _vm._e(), _vm.detail.customerType == 2 ? _c('van-cell', {
          attrs: {
            "title": "已收租金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v(_vm._s(_vm.detail.rentPaidAmount) + "元")])];
            },
            proxy: true
          }], null, false, 2751445655)
        }) : _vm._e(), _vm.detail.customerType == 2 ? _c('van-cell', {
          attrs: {
            "title": "应收租金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v(_vm._s(_vm.detail.rentAmount) + "元")])];
            },
            proxy: true
          }], null, false, 1393604427)
        }) : _vm._e(), _vm.detail.customerType == 2 ? _c('van-cell', {
          attrs: {
            "title": "违约租金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v(_vm._s(_vm.detail.rentBreakAmount) + "元")])];
            },
            proxy: true
          }], null, false, 990853204)
        }) : _vm._e(), _vm.detail.customerType == 2 ? _c('van-cell', {
          attrs: {
            "title": "应退租金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.detail.afterRentBreakAmount) + "元")])];
            },
            proxy: true
          }], null, false, 338713041)
        }) : _vm._e(), _c('van-cell', {
          attrs: {
            "title": "退还押金"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.detail.inDeposit) + "元")])];
            },
            proxy: true
          }], null, false, 3962856156)
        }), _c('van-field', {
          attrs: {
            "placeholder": "请输入已收水费",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("已收水费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3661784608),
          model: {
            value: _vm.detail.waterPaidAmount,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "waterPaidAmount", $$v);
            },
            expression: "detail.waterPaidAmount"
          }
        }), _c('van-field', {
          attrs: {
            "type": "number",
            "placeholder": "请输入应收水费",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("应收水费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 2013804294),
          model: {
            value: _vm.detail.waterTotalAmount,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "waterTotalAmount", $$v);
            },
            expression: "detail.waterTotalAmount"
          }
        }), _c('van-cell', {
          attrs: {
            "title": "水费结余"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.waterReturnAmount) + "元")])];
            },
            proxy: true
          }], null, false, 2159223815)
        }), _c('van-field', {
          attrs: {
            "placeholder": "请输入已收电费",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("已收电费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3000366305),
          model: {
            value: _vm.detail.powerPaidAmount,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "powerPaidAmount", $$v);
            },
            expression: "detail.powerPaidAmount"
          }
        }), _c('van-field', {
          attrs: {
            "placeholder": "请输入应收电费",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("应收电费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 2335221191),
          model: {
            value: _vm.detail.powerTotalAmount,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "powerTotalAmount", $$v);
            },
            expression: "detail.powerTotalAmount"
          }
        }), _c('van-cell', {
          attrs: {
            "title": "电费结余"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.powerReturnAmount) + "元")])];
            },
            proxy: true
          }], null, false, 1425348621)
        }), _c('van-field', {
          attrs: {
            "disabled": true,
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("水电结余费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 1696709979),
          model: {
            value: _vm.waterElectricity,
            callback: function ($$v) {
              _vm.waterElectricity = $$v;
            },
            expression: "waterElectricity"
          }
        }), _vm.detail.chargeAmount !== 0 ? _c('van-cell', {
          attrs: {
            "title": "挂账总金额"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v(_vm._s(_vm.detail.chargeAmount) + "元")])];
            },
            proxy: true
          }], null, false, 984442524)
        }) : _vm._e(), _c('van-field', {
          attrs: {
            "placeholder": "请输入赔偿费",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("赔偿费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 1302023706),
          model: {
            value: _vm.detail.damages,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "damages", $$v);
            },
            expression: "detail.damages"
          }
        }), _c('van-field', {
          attrs: {
            "placeholder": "请输入打扫费",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("打扫费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3714629705),
          model: {
            value: _vm.detail.cleanFee,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "cleanFee", $$v);
            },
            expression: "detail.cleanFee"
          }
        }), _c('van-field', {
          attrs: {
            "placeholder": "请输入额外增值费",
            "input-align": "right"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("额外增值费")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333",
                  "padding": "0 10px"
                }
              }, [_vm._v("元")])];
            },
            proxy: true
          }], null, false, 3700692024),
          model: {
            value: _vm.detail.otherFee,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "otherFee", $$v);
            },
            expression: "detail.otherFee"
          }
        }), _c('van-cell', {
          attrs: {
            "title": "应退还总费用",
            "title-style": "flex:2.5;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v(_vm._s(_vm.yingtuihuan) + "元")])];
            },
            proxy: true
          }, {
            key: "label",
            fn: function () {
              return [_vm.detail.customerType == 1 ? _c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.inDeposit) + "+" + _vm._s(~~_vm.waterElectricity) + "=" + _vm._s(_vm.yingtuihuan) + "元)")]) : _vm._e(), _vm.detail.customerType == 2 ? _c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.afterRentBreakAmount) + "+" + _vm._s(_vm.detail.inDeposit) + "+" + _vm._s(~~_vm.waterElectricity) + "=" + _vm._s(_vm.yingtuihuan) + "元)")]) : _vm._e(), _vm.detail.customerType == 3 ? _c('span', {
                staticStyle: {
                  "color": "#DBAE74"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.inDeposit) + "+" + _vm._s(~~_vm.waterElectricity) + "=" + _vm._s(_vm.yingtuihuan) + "元)")]) : _vm._e()];
            },
            proxy: true
          }], null, false, 1697525037)
        }), _c('van-cell', {
          attrs: {
            "title": "应缴纳总费用",
            "title-style": "flex:2.5;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v("-" + _vm._s(_vm.yingjiaona) + "元")])];
            },
            proxy: true
          }, {
            key: "label",
            fn: function () {
              return [_vm.detail.customerType == 1 && _vm.detail.chargeAmount !== 0 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "+" + _vm._s(_vm.detail.chargeAmount) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm.detail.customerType == 2 && _vm.detail.chargeAmount !== 0 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.breakAmount) + "+" + _vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "+" + _vm._s(_vm.detail.chargeAmount) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm.detail.customerType == 3 && _vm.detail.chargeAmount !== 0 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.breakAmount) + "+" + _vm._s(_vm.detail.breakAmount) + "+" + _vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "+" + _vm._s(_vm.detail.chargeAmount) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm.detail.customerType == 1 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm.detail.customerType == 2 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.breakAmount) + "+" + _vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm.detail.customerType == 3 ? _c('span', {
                staticStyle: {
                  "color": "#FF3333"
                }
              }, [_vm._v("(" + _vm._s(_vm.detail.breakAmount) + "+" + _vm._s(_vm.detail.rentBreakAmount) + "+" + _vm._s(_vm.detail.damages) + "+" + _vm._s(_vm.detail.cleanFee) + "+" + _vm._s(_vm.detail.otherFee) + "=" + _vm._s(_vm.yingjiaona) + "元)")]) : _vm._e()];
            },
            proxy: true
          }], null, false, 1987698483)
        }), _c('van-cell', {
          attrs: {
            "title": "实际总退房费用",
            "title-style": "flex:2.5;"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v(_vm._s(_vm.shiji) + "元")])];
            },
            proxy: true
          }, {
            key: "label",
            fn: function () {
              return [_vm.detail.customerType == 1 ? _c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("(" + _vm._s(_vm.yingtuihuan) + "-" + _vm._s(_vm.yingjiaona) + "=" + _vm._s(_vm.shiji) + "元)")]) : _vm._e(), _vm.detail.customerType == 2 || _vm.detail.customerType == 3 ? _c('span', {
                staticStyle: {
                  "color": "#323233"
                }
              }, [_vm._v("(" + _vm._s(_vm.yingtuihuan) + "-" + _vm._s(_vm.yingjiaona) + "=" + _vm._s(_vm.shiji) + "元)")]) : _vm._e()];
            },
            proxy: true
          }], null, false, 3890269313)
        })], 1)], 1) : _vm._e(), _vm.form.correctType == 5 ? _c('div', [_c('van-field', {
          attrs: {
            "readonly": "",
            "name": "renter",
            "label": "租客",
            "input-align": "right"
          },
          model: {
            value: _vm.rent.tenantName,
            callback: function ($$v) {
              _vm.$set(_vm.rent, "tenantName", $$v);
            },
            expression: "rent.tenantName"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "name": "payTime",
            "label": "支付时间",
            "input-align": "right"
          },
          model: {
            value: _vm.payDate,
            callback: function ($$v) {
              _vm.payDate = $$v;
            },
            expression: "payDate"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "name": "roomNo",
            "label": "房号",
            "input-align": "right"
          },
          model: {
            value: _vm.rent.roomNo,
            callback: function ($$v) {
              _vm.$set(_vm.rent, "roomNo", $$v);
            },
            expression: "rent.roomNo"
          }
        }), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "name": "picker",
            "label": "结算方式",
            "placeholder": "请选择",
            "input-align": "right",
            "right-icon": "arrow",
            "required": "",
            "rules": [{
              required: true
            }]
          },
          on: {
            "click": _vm.handleShowSettlement
          },
          model: {
            value: _vm.rent.accountType,
            callback: function ($$v) {
              _vm.$set(_vm.rent, "accountType", $$v);
            },
            expression: "rent.accountType"
          }
        }), _c('van-popup', {
          attrs: {
            "position": "bottom"
          },
          model: {
            value: _vm.showSettlement,
            callback: function ($$v) {
              _vm.showSettlement = $$v;
            },
            expression: "showSettlement"
          }
        }, [_c('van-picker', {
          attrs: {
            "title": "结算方式",
            "show-toolbar": "",
            "columns": _vm.settlements
          },
          on: {
            "confirm": _vm.settlementConfirm,
            "cancel": function ($event) {
              _vm.showSettlement = false;
            }
          }
        })], 1), _c('van-field', {
          attrs: {
            "readonly": "",
            "clickable": "",
            "name": "picker",
            "label": "付款时间",
            "placeholder": "请选择",
            "input-align": "right",
            "right-icon": "arrow",
            "required": "",
            "rules": [{
              required: true
            }]
          },
          on: {
            "click": _vm.handleShowRentAmountDate
          },
          model: {
            value: _vm.rent.factPayDate,
            callback: function ($$v) {
              _vm.$set(_vm.rent, "factPayDate", $$v);
            },
            expression: "rent.factPayDate"
          }
        }), _c('van-popup', {
          attrs: {
            "round": "",
            "position": "bottom"
          },
          model: {
            value: _vm.showRentAmountDatePicker,
            callback: function ($$v) {
              _vm.showRentAmountDatePicker = $$v;
            },
            expression: "showRentAmountDatePicker"
          }
        }, [_c('van-datetime-picker', {
          attrs: {
            "type": "datetime",
            "title": "选择年月日"
          },
          on: {
            "confirm": _vm.changeRentAmountDate,
            "cancel": function ($event) {
              _vm.showRentAmountDatePicker = false;
            }
          },
          model: {
            value: _vm.factPayDate,
            callback: function ($$v) {
              _vm.factPayDate = $$v;
            },
            expression: "factPayDate"
          }
        })], 1), _vm.rent.factRentAmount || _vm.rent.factRentAmount === 0 ? _c('div', [_c('van-divider'), _c('van-field', {
          attrs: {
            "readonly": "",
            "name": "kemu",
            "label": "费用科目",
            "input-align": "right"
          },
          model: {
            value: _vm.kemu1,
            callback: function ($$v) {
              _vm.kemu1 = $$v;
            },
            expression: "kemu1"
          }
        }), _c('van-field', {
          attrs: {
            "clickable": "",
            "label": "金额",
            "placeholder": "请输入金额",
            "input-align": "right",
            "disabled": _vm.form.correctStatus == 3,
            "rules": [{
              required: true
            }],
            "required": ""
          },
          model: {
            value: _vm.rent.factRentAmount,
            callback: function ($$v) {
              _vm.$set(_vm.rent, "factRentAmount", $$v);
            },
            expression: "rent.factRentAmount"
          }
        })], 1) : _vm._e(), _vm.rent.factDepositAmount && _vm.rent.factDepositAmount === 0 ? _c('div', [_c('van-divider'), _c('van-field', {
          attrs: {
            "readonly": "",
            "name": "kemu",
            "label": "费用科目",
            "input-align": "right"
          },
          model: {
            value: _vm.kemu2,
            callback: function ($$v) {
              _vm.kemu2 = $$v;
            },
            expression: "kemu2"
          }
        }), _c('van-field', {
          attrs: {
            "clickable": "",
            "label": "金额",
            "placeholder": "请输入金额",
            "input-align": "right",
            "disabled": _vm.form.correctStatus == 3,
            "rules": [{
              required: true
            }],
            "required": ""
          },
          model: {
            value: _vm.rent.factDepositAmount,
            callback: function ($$v) {
              _vm.$set(_vm.rent, "factDepositAmount", $$v);
            },
            expression: "rent.factDepositAmount"
          }
        })], 1) : _vm._e()], 1) : _vm._e(), _vm.form.correctStatus != 3 && _vm.form.correctType != 4 ? _c('div', {
          staticStyle: {
            "margin": "16px"
          }
        }, [_c('van-button', {
          attrs: {
            "round": "",
            "block": "",
            "type": "info",
            "color": "#D8B084",
            "native-type": "submit"
          }
        }, [_vm._v("提 交")])], 1) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };